/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/registration/new-registration/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    
    email,
    password,
    repeatPassword,
    address1,
    address2,
    city,
    zip,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio,
    
    age,
  
    // documentnumber,
    pan,
    adhar,
   
    referalcode,
    
    bankname,
    ifsccode,
    accountnumber,
    accountholdername ,
    mobile, 
    
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [twitter.name]: "",
  [facebook.name]: "",
  [instagram.name]: "",
  [publicEmail.name]: "",
  [bio.name]: "",
  [firstName.name]: "",
  [lastName.name]: "",
  [age.name]: "",
  [pan.name]:"",
  [adhar.name]:"",
  [password.name]: "",
  [repeatPassword.name]: "",
  [accountholdername.name]: "",
  [accountnumber.name]: "",
  [ifsccode.name]: "",
  [mobile.name]:"",
  [email.name]:"",

   [bankname.name]: "",
  [referalcode.name]: "",
  
  
  [twitter.name]:"",
};

export default initialValues;
