/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    age,
    username,
    // documentnumber,
    pan,
    adhar,
    password,
    referalcode,
    repeatPassword,
    bankname,
    ifsccode,
    accountnumber,
    accountholdername ,
    mobile, 
    email,
    twitter

    // documenttype,

  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [age.name]: "",
  [pan.name]:"",
  [adhar.name]:"",
  [password.name]: "",
  [repeatPassword.name]: "",
  [accountholdername.name]: "",
  [accountnumber.name]: "",
  [ifsccode.name]: "",
  [mobile.name]:"",
  [email.name]:"",

   [bankname.name]: "",
  [referalcode.name]: "",
  
  [username.name]: "",
  [twitter.name]:"",

};

export default initialValues;