import axios from "axios"

export const logIn = (data) => async (dispatch) => {
    dispatch({ type: "LOGIN_PENDING", payload: "" })
    try {

         const resp = await axios.post('https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/login', data)
       // const resp = await axios.post('http://localhost:6013/api/ProfileEntity/v2/login', data)

        dispatch({ type: "LOGIN_FULFILLED", payload: resp.data })
        getInvestmentprofile()
    } catch (error) {
        dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data })
    }
    async function getInvestmentprofile() {
        var profileId = JSON.parse(localStorage.getItem("mmu-auth")).profileid;

        await axios
            .get(
                 `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/get?profileid=${profileId}`
              //  `http://localhost:6012/api/investmentprofile/v1/get?profileid=${profileId}`
            )
            .then((response) => {
                console.log("20", response);
                if (response.data.data.dtoList.length > 0) {
                    localStorage.setItem(
                        "investment-profile",
                        JSON.stringify(response.data.data.dtoList[0])
                    );
                }

            })
            .catch((e) => {
                console.log("error", e);
            });



    }
}
