// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { Autocomplete } from "@mui/material";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";


import { useState } from "react";

import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";


function UserInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [documentValue, setDoumentValue] = useState(null);
  const [documentlist, setDocumentlist] = useState("");
  const [color] = useState("#344767");



  const { formField, values, errors, touched } = formData;

  const { firstName, lastName, age, documenttype, referalcode, pan, adhar, password, repeatPassword, username,
    bankname, ifsccode, accountnumber, accountholdername, email, mobile } =
    formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    age: ageV,
    // documentnumber: documentnumberV,
    pan: panV,
    adhar: adharV,
    mobile: mobileV,
    email: emailV,
    referalcode: referalcodeV,
    password: passwordV,
    accountholdername: accountholdernameV,
    accountnumber: accountnumberV,
    ifsccode: ifsccodeV,
    bankname: banknameV,
    repeatPassword: repeatPasswordV,
    username: usernameV,
  } = values;

  const defaultPropsdocumentype = {
    options: !documentlist
      ? [
        { name: "Pan Card", code: "X" },
        { name: "Addhar Card", code: "y" },
      ]
      : documentlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };


  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label={firstName.label}
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={username.type}
                label={username.label}
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={age.type}
                label={age.label}
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>

              <FormField
                type={referalcode.type}
                label={referalcode.label}
                name={referalcode.name}
                value={referalcodeV}
                placeholder={referalcode.placeholder}
                error={errors.referalcode && touched.referalcode}
                success={referalcodeV.length > 0 && !errors.referalcode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>

              <FormField
                type={adhar.type}
                label={adhar.label}
                name={adhar.name}
                value={adharV}
                placeholder={adhar.placeholder}
                error={errors.adhar && touched.adhar}
                success={adharV.length > 0 && !errors.adhar}

              />


              {/* <Autocomplete
                {...defaultPropsdocumentype}
                value={documentValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDoumentValue(newValue);

                  values.documenttype = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.documenttype && touched.documenttype}
                    // success={documenttype.length > 0 && !errors.documenttype}
                    variant="standard"
                    label={documenttype.label}
                  />
                )}
              /> */}
            </Grid>

          </Grid>


          <Grid container spacing={3}>


            <Grid item xs={12} sm={6}>
              <FormField
                type={pan.type}
                label={pan.label}
                name={pan.name}
                value={panV}
                placeholder={pan.placeholder}
                error={errors.pan && touched.pan}
                success={panV.length > 0 && !errors.pan}

              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={accountholdername.type}
                label={accountholdername.label}
                name={accountholdername.name}
                value={accountholdernameV}
                placeholder={accountholdername.placeholder}
                error={errors.accountholdername && touched.accountholdername}
                success={accountholdernameV.length > 0 && !errors.accountholdername}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={accountnumber.type}
                label={accountnumber.label}
                name={accountnumber.name}
                value={accountnumberV}
                placeholder={accountnumber.placeholder}
                error={errors.accountnumber && touched.accountnumber}
                success={accountnumberV.length > 0 && !errors.accountnumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={ifsccode.type}
                label={ifsccode.label}
                name={ifsccode.name}
                value={ifsccodeV}
                placeholder={ifsccode.placeholder}
                error={errors.ifsccode && touched.ifsccode}
                success={ifsccodeV.length > 0 && !errors.ifsccode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={bankname.type}
                label={bankname.label}
                name={bankname.name}
                value={banknameV}
                placeholder={bankname.placeholder}
                error={errors.bankname && touched.bankname}
                success={banknameV.length > 0 && !errors.bankname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={mobile.type}
                label={mobile.label}
                name={mobile.name}
                value={mobileV}
                placeholder={mobile.placeholder}
                error={errors.mobile && touched.mobile}
                success={mobileV.length > 0 && !errors.mobile}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            

            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label={repeatPassword.label}
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />

            </Grid>
          </Grid>






        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;

