// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

import axios from "axios";
import { BarLoader } from "react-spinners";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


function InvestmentList() {
    const comp = "SM";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [color] = useState("#344767");
    const [getResponse, setResponse] = useState([]);
    const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
    const [totalClosingAmount, setTotalClosingAmount] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [toatlProfiles, setToatlProfiles] = useState(0);

    // const [tableStatus, setTableStatus] = useState();

    const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
    const addButtonStyle = {
        display: "flex",
        fontSize: "2em",
        flexDirection: "column",
        marginRight: "10px",
    };

    function roundDown(value, digits=2){
        value = (value*(Math.pow(10,digits)));
        value = Math.floor(value);
        value = value/(Math.pow(10,digits));
        return (value);
    }
    function trueRound(value, digits){
        return (Math.round((value*Math.pow(10,digits)).toFixed(digits-1))/Math.pow(10,digits)).toFixed(digits);
    }

    async function getData() {
        var referalcode = JSON.parse(localStorage.getItem("investment-profile")).referalcode;
        setLoading(true);

        await axios
            .get(
                 `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}`
            //    `http://localhost:6011/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}`
            )
            .then((response) => {
                console.log("63", response)
                setToatlProfiles(response.data.data.investmentProfileAggregation.length);
                var closing = 0;
                var opening = 0;
                for (let i = 0; i < response.data.data.investmentProfileAggregation.length; i++) {
                    console.log(response.data.data.investmentProfileAggregation[i]);
                    var data = response.data.data.investmentProfileAggregation[i];
                    // data.investmentprofile?.closingbalance = roundDown(data.investmentprofile?.closingbalance);
                    // roundDown(data.investmentprofile?.openingbalance)
                    console.log("clos", roundDown(data.investmentprofile?.closingbalance));
                    closing = closing + roundDown(data.investmentprofile?.closingbalance);
                    opening = opening + roundDown(data.investmentprofile?.openingbalance);
                    // setTotalClosingAmount(closing + data.investmentprofile?.closingbalance);
                    // setTotalOpeningAmount(totalOpeningAmount + data.investmentprofile?.openingbalance);
                    // }
                    // response.data.data.dtoList.map((item) => {
                    const dataObj = {
                        columns: [
                            { Header: "Commission Type", accessor: "commissiontype", },
                            { Header: "commission", accessor: "commission", },
                            { Header: "investment term(months)", accessor: "investmentterm", },
                            { Header: "amount", accessor: "amount", },
                            { Header: "current valuation", accessor: "currentvaluation", },
                            { Header: "STATUS", accessor: "recstatus", },
                            { Header: "starting Date", accessor: "fstartdate", },
                            { Header: "end Date", accessor: "fenddate", },
                            { Header: "closing Date", accessor: "fclosingdate", },

                        ],
                        rows: []
                        // rows: response.data.data.investmentProfileAggregation.investmentitemlist
                    };


                    response.data.data.investmentProfileAggregation[i].investmentItemlist.map((i) => {
                        i.currentvaluation = roundDown(i.currentvaluation);
                        if (i.startdate != null) {
                            i.fstartdate = new Date(i.startdate).toDateString();
                        }
                        if (i.enddate != null) {
                            i.fenddate = new Date(i.enddate).toDateString();
                        }
                        if (i.closingdate != null) {
                            i.fclosingdate = new Date(i.closingdate).toDateString();
                        }



                        dataObj.rows.push(i)
                    })



                    response.data.data.investmentProfileAggregation[i].dataObj = dataObj;
                };
                setTotalClosingAmount(roundDown(closing));
                setTotalOpeningAmount(roundDown(opening));
                setTotalCommission(roundDown(closing - opening));
                setResponse(response.data.data.investmentProfileAggregation);
                console.log("====================================");
                console.log(getResponse);
                console.log(response.data.data.investmentProfileAggregation);
                console.log("====================================");

                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }
    useEffect(() => {
        getData();

    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3}>
                <MDBox>
                    <Card>
                        <MDBox pl={3} pr={3} lineHeight={1}>
                            <div style={addButtonStyle}>
                                <span
                                    style={{
                                        alignSelf: "flex-start",
                                        marginTop: "20px",
                                    }}
                                >
                                    <MDTypography variant="h5" fontWeight="medium">
                                        {/* Investment Redeem */}
                                    </MDTypography>
                                </span>

                                <Link
                                    style={{
                                        alignSelf: "flex-end",
                                        marginRight: "10px",
                                        marginTop: "-23px",
                                        color: "#344767",
                                    }}
                                    to={"pages/stock/create-stock"}
                                >
                                    {/* <MdOutlineLibraryAdd /> */}
                                </Link>
                            </div>
                        </MDBox>
                        <BarLoader
                            color={color}
                            loading={loading}
                            aria-label="Loading Spinner"
                        />
                        <MDBox mt={1.5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="dark"
                                            icon="weekend"
                                            title="Total Profiles"
                                            count={toatlProfiles}
                                            // percentage={{
                                            //     color: "success",
                                            //     amount: "+55%",
                                            //     label: "than lask week",
                                            // }}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            icon="leaderboard"
                                            title="Opening"
                                            count={totalOpeningAmount}
                                            // percentage={{
                                            //     color: "success",
                                            //     amount: "+3%",
                                            //     label: "than last month",
                                            // }}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="success"
                                            icon="store"
                                            title="Closing"
                                            count={totalClosingAmount}
                                            // percentage={{
                                            //     color: "success",
                                            //     amount: "+1%",
                                            //     label: "than yesterday",
                                            // }}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="primary"
                                            icon="person_add"
                                            title="Commission"
                                            count={totalCommission}
                                            // percentage={{
                                            //     color: "success",
                                            //     amount: "",
                                            //     label: "Just updated",
                                            // }}
                                        />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <div>
                            {getResponse.map((values, i) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div style={{ fontSize: "15px", color: "#7b809a" }}>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>
                                                        Name:
                                                    </text>
                                                    {" " + values.investmentprofile?.name}


                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>
                                                        ReferalCode :
                                                    </text>
                                                    {" " + values?.investmentprofile?.referalcode}

                                                </div>


                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Age :
                                                    </text>
                                                    {" " + values.investmentprofile?.age}
                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>Closing Balance :</text>
                                                    {" " + roundDown(values.investmentprofile?.closingbalance)} 

                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>Opening Balance :</text>
                                                    {" " + values.investmentprofile?.openingbalance}

                                                </div>
                                                {/* <div>

                                                    <text style={{ fontWeight: "bold" }}>Status :</text>
                                                    {" " + values.investmentprofile?.recstatus}

                                                </div> */}





                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <text style={{ color: "#FF00FF" }}>
                                                <DataTable
                                                    table={values.dataObj}
                                                    showTotalEntries={false}
                                                    entriesPerPage={false}
                                                />
                                            </text>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}


export default InvestmentList