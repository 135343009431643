import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Switch } from "@mui/material";
import swal from "sweetalert";

function BankDetail() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [bankName, setBankName] = useState();
  const [response, setResponse] = useState([]);
  const [ifscCode, setIfscCode] = useState();
  const [holderName, setHolderName] = useState();
  const [accNumber, setAccNumber] = useState();
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("mmu-auth")));
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  function radioCheck(e) {
    console.log("57", e.target.checked);
    // setChecked(e.target.checked)
    if (e.target.checked == true) {
      console.log("PRIMARY");
      // setChecked(true)
    } else {
      console.log("OPEN");
      // setChecked(true)
    }

    // console.log("58", checked);
    // setSelectedValue(event.target.value);
    //  alert('hellow')
  }
  function update(object) {

    axios
      .put(
        `https://apithf.softwaremathematics.com/bankaccounts1-service/api/Bankaccounts1/v1/update`,
        // `http://localhost:6001/api/Bankaccounts1/v1/update`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        getData(profile?.profileid)
      })
      .catch((e) => {
        console.log("error", e);
        getData(profile?.profileid)
      });
  }

  const toggler = (checked, request) => {
    console.log("toggle 86", checked);
    delete request.action;
    if (checked) {
      request.recstatus = "PRIMARY";

      console.log("req 88", request.recstatus);
    } else {
      request.recstatus = "OPEN";

      console.log("req 91", request.recstatus);
    }
    update(request);
  };

  function onDelete(object) {
    object.status = "INACTIVE";
    swal({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this Bank Detail",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        console.log(willDelete)
        if (willDelete) {
          update(object);


        }
      })

  }

  function getData(profileid) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: " bank name", accessor: "bankname" },
        { Header: "ifsc code", accessor: "ifsc" },
        { Header: "Acc holder name", accessor: "holdername" },
        { Header: "Account number", accessor: "accountno" },
        { Header: "Set as primary", accessor: "action" },
        { Header: "delete", accessor: "delete" },
      ],
      rows: [],
    };
    axios
      .get(
        `https://apithf.softwaremathematics.com/bankaccounts1-service/api/Bankaccounts1/v1/get?profileid=${profileid}`
        // `http://localhost:6001/api/Bankaccounts1/v1/get?profileid=${profileid}`,
      )
      .then((response) => {
        setResponse(response.data.data.dtoList);
        response.data.data.dtoList.map((item) => {
          if (item.recstatus == "PRIMARY") {
            // setChecked(true);
            item.action = (

              <FormControlLabel
                name={item.accountno}
                control={
                  <Switch
                    onClick={(e) =>
                      toggler(e.target.checked, item)
                    }
                    defaultChecked
                  />
                }
                label=""
              />

            );
          }
          if (item.recstatus == "OPEN") {
            // setChecked(true);
            item.action = (
              <span>
                <FormControlLabel
                  name={item.accountno}
                  control={
                    <Switch
                      onClick={(e) =>
                        toggler(e.target.checked, item)
                      }
                    />
                  }
                  label=""
                />



              </span>



            );
          }

          item.delete = (<Link to={{}}>
          <MdDelete
            style={iconStyle}
            onClick={() => {
              onDelete(item);
            }}
          /></Link>)
          // item.createddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function create(object) {

    axios
      .post(
        `https://apithf.softwaremathematics.com/bankaccounts1-service/api/Bankaccounts1/v1/create`,
        // `http://localhost:6001/api/Bankaccounts1/v1/create`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        getData(profile?.profileid);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }



  async function addData() {
    const dataObj = {
      bankname: bankName,
      ifsc: ifscCode,
      accountno: accNumber,
      holdername: holderName,
      profileid: profile?.profileid,
      recstatus: "OPEN",
      status: "Active",
      syncstatus: "SYNCED"
    }
    console.log(dataObj);
    create(dataObj);
    // getData(profile?.profileid)
    setOpen(false);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  useEffect(() => {
    getData(profile?.profileid);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Bank Details
                  </MDTypography>
                </span>

                <Link

                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={""}
                >
                  {response.length >= 3 ?
                    <MdOutlineLibraryAdd onClick={() => { swal("You are exceeding the limit of Bank Details..") }} />
                    : <MdOutlineLibraryAdd onClick={() => { setOpen(true) }} />
                  }
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Bank detail</DialogTitle>
        <DialogContent style={{ width: 550, height: 270 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Bank Name"
              variant="standard"
              fullWidth
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="IFSC Code"
              variant="standard"
              fullWidth
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Account Holder Name"
              variant="standard"
              fullWidth
              value={holderName}
              onChange={(e) => setHolderName(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Account Number"
              variant="standard"
              fullWidth
              value={accNumber}
              onChange={(e) => setAccNumber(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addData}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}


export default BankDetail;