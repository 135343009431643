/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/registration/new-registration/components/FormField"; 

function RegistrationInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, age,  referalcode, pan, adhar, password, repeatPassword, 
     email, mobile } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    age: ageV,
    
    pan: panV,
    adhar: adharV,
    mobile: mobileV,
    email: emailV,
    referalcode: referalcodeV,
    password: passwordV,
   
    repeatPassword: repeatPasswordV,
    
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">About me</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
          <FormField
                type={age.type}
                label={age.label}
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormField
                type={referalcode.type}
                label={referalcode.label}
                name={referalcode.name}
                value={referalcodeV}
                placeholder={referalcode.placeholder}
                error={errors.referalcode && touched.referalcode}
                success={referalcodeV.length > 0 && !errors.referalcode}
              />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
          <FormField
                type={adhar.type}
                label={adhar.label}
                name={adhar.name}
                value={adharV}
                placeholder={adhar.placeholder}
                error={errors.adhar && touched.adhar}
                success={adharV.length > 0 && !errors.adhar}

              />
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormField
                type={pan.type}
                label={pan.label}
                name={pan.name}
                value={panV}
                placeholder={pan.placeholder}
                error={errors.pan && touched.pan}
                success={panV.length > 0 && !errors.pan}

              />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
          <FormField
                type={mobile.type}
                label={mobile.label}
                name={mobile.name}
                value={mobileV}
                placeholder={mobile.placeholder}
                error={errors.mobile && touched.mobile}
                success={mobileV.length > 0 && !errors.mobile}
                inputProps={{ autoComplete: "" }}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
                inputProps={{ autoComplete: "" }}
              />
          </Grid>
        </Grid>
        
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for RegistrationInfo
RegistrationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegistrationInfo;
