// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import swal from 'sweetalert';

import axios from "axios";
import { BarLoader } from "react-spinners";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";


function InvestmentRedeem() {
    const comp = "SM";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [color] = useState("#344767");
    const [getResponse, setResponse] = useState([]);
    const [disablewithdrawal, setDisablewithdrawal] = useState(false);

    // const [tableStatus, setTableStatus] = useState();

    const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
    const addButtonStyle = {
        display: "flex",
        fontSize: "2em",
        flexDirection: "column",
        marginRight: "10px",
    };
    async function updateInvestmentProfile(completelist) {
        console.log("53", completelist)
        var objectlist = []
        var itemObjlist = []
        for (let i = 0; i < completelist.length; i++) {
            completelist[i].investmentprofile.recstatus = "WITHDRAWL_REQUESTED";
            objectlist.push(completelist[i].investmentprofile)
            for (let j = 0; j < completelist[i].investmentItemlist.length; j++) {
                completelist[i].investmentItemlist[j].closingdate = new Date();
                completelist[i].investmentItemlist[j].recstatus = "WITHDRAWAL";
                itemObjlist.push(completelist[i].investmentItemlist[j])
            }
        }
        console.log("59", objectlist)
        updateInvestmentItem(itemObjlist);
        await axios
            .post(
                'https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/createList', objectlist
               // 'http://localhost:6012/api/investmentprofile/v1/createList', objectlist
            )
            .then((response) => {
              //  navigate('/pages/users/investment-redeem')
                swal("InvestmentItem!", "...Sucessfully update!");
                          getData();     

            })
            .catch((e) => {
                swal("Error!", "...Oops Something Went Wrong!");

            });
    }
    // line item==========================
    async function updateInvestmentItem(objectlist) {
        console.log("79", objectlist)


        await axios
            .post(
                 'https://apithf.softwaremathematics.com/investmentitem-service/api/investmentitem/v1/createList', objectlist
                //'http://localhost:6011/api/investmentitem/v1/createList', objectlist
            )
            .then((response) => {

            })
            .catch((e) => {
                swal("Error!", "...Oops Something Went Wrong!");

            });
    }


    async function getData() {
        var referalcode = JSON.parse(localStorage.getItem("investment-profile")).referalcode;
        setLoading(true);

        await axios
            .get(
                 `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}&recstatus=APPROVED`
              //  `http://localhost:6012/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}&recstatus=APPROVED`
            )
            .then((response) => {
                console.log("63", response)
                if (response.data.data?.investmentProfileAggregation[0].investmentprofile.recstatus==="WITHDRAWL_REQUESTED") {
                    setDisablewithdrawal(true);
                }
                for (let i = 0; i < response.data.data.investmentProfileAggregation.length; i++) {

                    // }
                    // response.data.data.dtoList.map((item) => {
                    const dataObj = {
                        columns: [
                            { Header: "Commission Type", accessor: "commissiontype", },
                            { Header: "commission", accessor: "commission", },
                            { Header: "investment term(months)", accessor: "investmentterm", },
                            { Header: "amount", accessor: "amount", },
                            { Header: "current valuation", accessor: "currentvaluation", },
                            { Header: "starting Date", accessor: "fstartdate", },
                            { Header: "end Date", accessor: "fenddate", },


                        ],
                        rows: []
                        // rows: response.data.data.investmentProfileAggregation.investmentitemlist
                    };
                   

                    response.data.data.investmentProfileAggregation[i].investmentItemlist.map((i) => {
                        if (i.startdate != null) {
                            i.fstartdate = new Date(i.startdate).toDateString();
                        }
                        if (i.enddate != null) {
                            i.fenddate = new Date(i.enddate).toDateString();
                        }



                        dataObj.rows.push(i)
                    })



                    response.data.data.investmentProfileAggregation[i].dataObj = dataObj;
                };
                setResponse(response.data.data.investmentProfileAggregation);
                console.log("====================================");
                console.log(getResponse);
                console.log(response.data.data.investmentProfileAggregation);
                console.log("====================================");

                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
                
            });
            setLoading(false);
    }
    useEffect(() => {
        getData();

    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3}>
                <MDBox>
                    <Card>
                        <MDBox pl={3} pr={3} lineHeight={1}>
                            <div style={addButtonStyle}>
                                <span
                                    style={{
                                        alignSelf: "flex-start",
                                        marginTop: "20px",
                                    }}
                                >
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Investment Redeem
                                    </MDTypography>
                                </span>

                                <Link
                                    style={{
                                        alignSelf: "flex-end",
                                        marginRight: "10px",
                                        marginTop: "-23px",
                                        color: "#344767",
                                    }}
                                    to={""}
                                >
                                    <MDButton color="info" 
                                    //disabled={disablewithdrawal}
                                    onClick={() => updateInvestmentProfile(getResponse)}>Withdrawal</MDButton>
                                </Link>
                            </div>
                        </MDBox>
                        <BarLoader
                            color={color}
                            loading={loading}
                            aria-label="Loading Spinner"
                        />
                        <div>
                            {getResponse.map((values, i) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div style={{ fontSize: "15px", color: "#7b809a" }}>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>
                                                        Name:
                                                    </text>
                                                    {" " + values.investmentprofile?.name}


                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>
                                                        ReferalCode :
                                                    </text>
                                                    {" " + values?.investmentprofile?.referalcode}

                                                </div>


                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Age :
                                                    </text>
                                                    {" " + values.investmentprofile?.age}
                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>Closing Balance :</text>
                                                    {" " + values.investmentprofile?.closingbalance}

                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>Opening Balance :</text>
                                                    {" " + values.investmentprofile?.openingbalance}

                                                </div>
                                                <div>

                                                    <text style={{ fontWeight: "bold" }}>Status :</text>
                                                    {" " + values.investmentprofile?.recstatus}

                                                </div>
                                                <div>



                                                </div>
                                                {/* <Grid item xs={12} sm={6}>
                                                    <text style={{ fontWeight: "bold" }}>
                                                    Document Type :
                                                    </text>
                                                    {" " + values.medrequisitiongentxndto?.mmucode}
                                                    </Grid> */}



                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <Typography>
                                            <text style={{ color: "#FF00FF" }}>
                                                <DataTable
                                                    table={values.dataObj}
                                                    showTotalEntries={false}
                                                    entriesPerPage={false}
                                                />

                                            </text>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}





export default InvestmentRedeem