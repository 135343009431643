/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";

import checkout from "layouts/pages/registration/new-registration/schemas/form";

const {
  formField: {  firstName,
    lastName,
    age,
   
    // documentnumber,
    password,
    pan,
    adhar,
    // documenttype,
    repeatPassword,
    bankname,
    ifsccode,
    accountnumber,
    accountholdername ,
    mobile, email, referalcode },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    [pan.name]: Yup.string()
    .required(pan.errorMsg).matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "invalid PAN no")
    .min(10, pan.invalidMsg)
    .max(10, pan.invalidMsg),
    // [pan.name]: Yup.string().required(pan.errorMsg),
    [adhar.name]: Yup.string()
      .required(adhar.errorMsg)
      .min(12, adhar.invalidMsg)
      .max(12, adhar.invalidMsg),
   
    [referalcode.name]: Yup.string().required(referalcode.errorMsg),
    [mobile.name]: Yup.string()
      .required(mobile.errorMsg)
      .min(10, mobile.invalidMsg)
      .max(10, mobile.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
    [accountholdername.name]: Yup.string().required(accountholdername.errorMsg),
    [accountnumber.name]: Yup.string().required(accountnumber.errorMsg),
    [ifsccode.name]: Yup.string().required(ifsccode.errorMsg),
    [bankname.name]: Yup.string().required(bankname.errorMsg),
  }),
 
];

export default validations;
