import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newtype page components
import InvestmentItemInfo from "layouts/pages/InvestmentItem/new-InvestmentItem/componets/InvestmentItemInfo";

// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/InvestmentItem/new-InvestmentItem/schemas/validations";
import form from "layouts/pages/InvestmentItem/new-InvestmentItem/schemas/form";
import initialValues from "layouts/pages/InvestmentItem/new-InvestmentItem/schemas/initialValues";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { object } from "prop-types";


function getSteps() {
  return ["Role Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <InvestmentItemInfo formData={formData} />;

    default:
      return null;
  }
}

function Investment() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem("mmu-auth")));
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

async function investmentProfile(){
  
  var object = JSON.parse(localStorage.getItem("investment-profile"));
  object.recstatus="APPROVED"
  await axios
      .put(
         'https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/update',object
       // 'http://localhost:6012/api/investmentprofile/v1/update',object
        
      )

      .then((response) => {

        console.log("70", response);
       // navigate('/pages/users/investment-list');
      })
      .catch((error) => {
        console.log(error);
        alert("oops something wrong")
      });
}


  const submitForm = async (values, actions) => {
    
    console.log("hii", values);
    
    const getData = {

      
      commission: values.commission,
      commissiontype: values.comisssiontype,
      createdby: loginUser.firstname + ' ' + loginUser.lastname,
      txnno:values.transationNo,
      currency: null,
      documentofproof: "",
      enddate: new Date(values.enddate),
      id: null,
      investmentterm: values.investmentterm,
      profileid: loginUser.profileid,
      amount: values.amount,

      recstatus: "OPEN",
      startdate: new Date(values.startdate),
      status: "ACTIVE",


    }
    console.log("payload", getData);
    await axios
      .post(
        'https://apithf.softwaremathematics.com/investmentitem-service/api/investmentitem/v1/create',
       // 'http://localhost:6011/api/investmentitem/v1/create',
        getData
      )

      .then((response) => {
        investmentProfile();
        console.log("gfgfgf", response);
        navigate('/pages/users/investment-list');
      })
      .catch((error) => {
        console.log(error);
        alert("oops something wrong")
      });


    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-1}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
         Add Investment
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your detail
        </MDTypography>
      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={() => {
                              console.log("submit");
                            }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Investment;
