/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React examples

import Footer from "examples/Footer";
import axios from "axios";
// NewUser page components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/registration/new-registration/schemas/validations";
import form from "layouts/pages/registration/new-registration/schemas/form";
import initialValues from "layouts/pages/registration/new-registration/schemas/initialValues";
import RegistrationInfo from "./components/RegistrationInfo";
import BankDetailInfo from "./components/BankDetail";
import swal from 'sweetalert';
import boards from "layouts/applications/kanban/data";

function getSteps() {
  return ["User Info", "Bank Detail",];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <RegistrationInfo formData={formData} />;
    case 1:
      return <BankDetailInfo formData={formData} />;

    default:
      return null;
  }
}

function NewRegistration() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  async function getInvestmentprofile(referalcode, profileEntityObject, investmentProfileObject, bankObj) {
    await axios
      .get(
        `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/get?referalcode=${referalcode}`
        // `http://localhost:6012/api/investmentprofile/v1/get?referalcode=${referalcode}`

      )

      .then((response) => {
        if (response.data.data.dtoList.length == 0) {
          swal("Alert!", "...Invalid Referal Code!");
        }
        else {
          investmentProfileObject.hierarchicalcode = response.data.data.dtoList[0].hierarchicalcode
          createprofileentity(profileEntityObject, investmentProfileObject, bankObj);


          swal("Good job!", "your registration has been done!", "success");
          navigate('/authentication/sign-in/basic');
        }

        console.log("66", response);
      })
      .catch((error) => {
        console.log("Something Went Wrong", error);
        swal("Oops!", "...Something Went Wrong!");
      });



  }
  //'https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/create'
  async function createInvestmentprofile(object) {
    await axios
      .post(
        'https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/create',
        // 'http://localhost:6012/api/investmentprofile/v1/create',
        object
      )

      .then((response) => {
        console.log("gfgfgf", response);

      })
      .catch((error) => {
        console.log(error);
      });

  }
  async function createprofileentity(object, investmentProfileObject, bankObj) {
    await axios
      .post(
        "https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/create",
        //  "http://localhost:6013/api/ProfileEntity/v2/create",
        object
      )

      .then((response) => {
        investmentProfileObject.profileid = response.data.data.dto.profileid
        bankObj.profileid=response.data.data.dto.profileid;
        createInvestmentprofile(investmentProfileObject);
        addBankAccount(bankObj);

        // setProfileid(response.data.data.dto.profileid)
        console.log("111", response.data.data.dto.profileid)
        console.log("gfgfgf", response);

      })
      .catch((error) => {
        console.log("profile error", error);
        if (error.response.data.aceErrors.length > 0) {
          swal("Oops!", error.response.data.aceErrors[0].errorMessage);
        }
      });

  }

  function addBankAccount(object) {

    axios
      .post(
        `https://apithf.softwaremathematics.com/bankaccounts1-service/api/Bankaccounts1/v1/create`,
        // `http://localhost:6001/api/Bankaccounts1/v1/create`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        getData(profile?.profileid);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const submitForm = async (values, actions) => {
    console.log("hii", values);

    const profileEntityObject = {
      createdby: null,
      createddate: null,
      documentofproof: "",

      firstname: values.firstName,

      id: null,
      lastname: values.lastName,

      password: values.password,
      emailid: values.email,
      phoneno: values.mobile,

      recstatus: "APPROVED",

      resourcecode: "INVESTMENT",

      status: "ACTIVE",
      syncstatus: "SYNCED",

      username: values.mobile,

    };
    const investmentprofileObeject = {


      age: values.age,
      approvedby: null,
      approveddate: null,
      approver: "string",
      approvertype: "string",
      closingbalance: 0,
      createdby: "string",
      createddate: "",
      currency: 0,
      documentnumber: values.documentnumber,
      documentofproof: " ",
      documenttype: values.documenttype,
      hierarchicalcode: "",
      id: null,
      latlong: "",
      location: null,
      modifiedby: "",
      modifieddate: "",
      name: values.lastName ? values.firstName + ' ' + values.lastName : values.firstName,
      openingbalance: 0,
      profileid: "",
      recstatus: "APPROVED",
      referalcode: values.referalcode,
      status: "Active",
      syncstatus: "SYNCED",
      bankdetail: {
        adhar: values.adhar,
        pan: values.pan,
        bankname: values.bankname,
        ifsc: values.ifsccode,
        accountno: values.accountnumber,
        holdername: values.accountholdername
      },
      tempid: ""
    };
    const bankObj = {

      bankname: values.bankname,

      ifsc: values.ifsccode,

      accountno: values.accountnumber,

      holdername: values.accountholdername,

      profileid: "",

      recstatus: "PRIMARY",

      status: "Active",

      syncstatus: "SYNCED"

    }

    // console.log("195,", profileEntityObject);

    getInvestmentprofile(values.referalcode, profileEntityObject, investmentprofileObeject, bankObj)



    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);


    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: -4 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%", width: "650px", marginLeft: "-150px" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "Submit" : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "12px" }}>Already have an account?</span>
                        <Link
                          style={{

                            marginLeft: "5px",
                            fontWeight: "bold",

                            color: "blue",
                            fontSize: "16px"
                          }}
                          to={'/authentication/sign-in/basic'}
                        >
                          Sign In
                        </Link>
                      </div>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </CoverLayout>
  );
}

export default NewRegistration;
