import * as Yup from "yup";
import checkout from "layouts/pages/InvestmentItem/new-InvestmentItem/schemas/form";
const {
  formField:{comisssion,investmentterm,amount,transationNo},
} = checkout;

const validations = [
  Yup.object().shape({
    
    [amount.name]: Yup.string().required(amount.errorMsg),
    [comisssion.name]: Yup.string().required(comisssion.errorMsg),
    [investmentterm.name]: Yup.string().required(investmentterm.errorMsg),
    [transationNo.name]: Yup.string().required(transationNo.errorMsg),
  }),
];

export default validations;
