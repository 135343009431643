// import React,{useEffect,useRef} from "react";
// import { useForm } from "react-cool-form";
// import MDInput from "components/MDInput";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import { logIn } from "../../../../redux/Actions/authActions"
// import { useDispatch, useSelector } from "react-redux"
// import lottie from "lottie-web";
// //navigate to other screen=============
// import { Link } from "react-router-dom";
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// export default function Basic() {

//   const navigate = useNavigate();
//   const dispatch = useDispatch()
//   const container = useRef(null);
//   const {isAuthenticated } = useSelector((({ AuthReducer }) => AuthReducer))

//   const { form, use } = useForm({

//     defaultValues: { email: "", password: "", resourcecode: "INVESTMENT" },

//     onSubmit: (values) => loginUser(values)

//   });


//   const errors = use("errors");


//   const loginUser = async (data) => {
//     const getData = {
//       username: data.email,
//       password: data.password,
//       resourcecode: "INVESTMENT"

//     };

//     dispatch(logIn(getData))
//   };

//   useEffect(() => {
//     if(isAuthenticated){
      
//       navigate('/dashboards/analytics')
//     }
//   }, [isAuthenticated])
  
//   useEffect(() => {
//     lottie.loadAnimation({
//       container: container.current,
//       renderer: "svg",
//       autoplay: true,
     
//       animationData: require("./gear.json"),
//     });
//   }, []);


//   return (
//     <BasicLayout image={bgImage}>
   
//       <Card style={{height:"450px",width:"440px"}}>
     

//         <MDBox
//           variant="gradient"
//           bgColor="info"
//           borderRadius="lg"
//           coloredShadow="info"
//           mx={2}
//           mt={-3}
//           p={2}
//           mb={1}
//           textAlign="center"
//         >
//           <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//         Investment Panel LOGIN
//           </MDTypography>

//         </MDBox>
        
       
       
//         <form ref={form} noValidate>

//           <MDBox  mt={4} pt={4} pb={5} px={5}>
        
//             <MDBox mb={5}>
//             <MDTypography variant="h6"  color="black">
//               Username
//             </MDTypography>
    
//               <MDInput name="email" placeholder="Username" required style={{width:"370px"}}/>
//               {errors.email && <p style={{ fontWeight: "400px", color: "red",fontSize:"12px"}}>{errors.email}</p>}

//             </MDBox>
//             <MDBox mb={2} mt={4}>
//             <MDTypography variant="h6"  color="black">
//               Password
//             </MDTypography>
//               <MDInput name="password" type="password" placeholder="Password" required style={{width:"370px",}}/>
//               {errors.password && <p style={{ fontWeight: "400px", color: "red",fontSize:"12px"}}>{errors.password}</p>}
//             </MDBox>

//             <MDBox mb={1}>
//               <MDButton variant="gradient" color="info" fullWidth type="submit">
//                 Log In
//               </MDButton>
//             </MDBox>  

//           </MDBox>
         
//         </form>
//         <HowToRegIcon fontSize="medium" style={{marginTop:"-40px",marginLeft:"143px"}}/>
//         <Link
//                   style={{
//                     alignSelf: "flex-end",
//                     marginRight: "150px",
//                    fontWeight: "bold",
//                    marginTop:"-24px",
//                     color: "blue",
//                     fontSize:"16px"
//                   }}
//                   to={"/pages/users/new-registration"}
//                 >
//                   new registration
//                 </Link>
        
       
                
         
       
       
//       </Card>
//       <MDBox mt={-32} mr={-55}>
//           <MDBox
//             ml={60}
//             style={{ height: "100%", breath: "100%" }}
//             ref={container}
//           >
            
//           </MDBox>
//         </MDBox>
      
//     </BasicLayout>
//   );
// }




import React, { useEffect, useRef } from "react";
import { useForm } from "react-cool-form";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { logIn } from "../../../../redux/Actions/authActions"
import { useDispatch, useSelector } from "react-redux"
import lottie from "lottie-web";
//navigate to other screen=============
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
export default function Basic() {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const container = useRef(null);
  const { isAuthenticated } = useSelector((({ AuthReducer }) => AuthReducer))

  const { form, use } = useForm({

    defaultValues: { email: "", password: "", resourcecode: "INVESTMENT" },

    onSubmit: (values) => loginUser(values)

  });


  const errors = use("errors");


  const loginUser = async (data) => {
    const getData = {
      username: data.email,
      password: data.password,
    resourcecode: "INVESTMENT"

    };

    dispatch(logIn(getData))
  };

  useEffect(() => {
    if (isAuthenticated) {

    navigate('/dashboards/analytics')
    }
  }, [isAuthenticated])

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,

      animationData: require("./gear.json"),
    });
  }, []);


  return (
    <BasicLayout image={bgImage}>




      <Card height="100%">

        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            Sign In
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your username and password to register
          </MDTypography>
        </MDBox>




        <form ref={form} noValidate>

          <MDBox pt={4} pb={3} px={3}>

            <MDBox>

              <MDBox mb={2}>
                <MDInput name="email" placeholder="Username" required style={{ width: "310px" }} />
                {errors.email && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.email}</p>}
              </MDBox>



              <MDBox mb={2}>

                <MDInput name="password" type="password" placeholder="Password" required style={{ width: "310px" }} />
                {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
              </MDBox>

              <MDBox mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Log In
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>

        </form>

        
        <div style={{textAlign:"center"}}>
                            <span style={{ fontSize: "12px" }}>don't have an account?</span>
                            <Link
                              style={{

                                marginLeft: "5px",
                                fontWeight: "medium",

                              
                                fontSize: "16px"
                              }}
                              to={'/pages/users/new-registration'}
                            >
                             <span style={{ fontSize: "14px",fontWeight:"bold",color:"blue" }}>Sign up now</span> 
                            </Link>
                            </div>
          
          

          
        
        {/* <MDBox mb={1} textAlign="center">




</MDBox> */}

      </Card>
      <MDBox mt={-32} mr={-55}>
        <MDBox
          ml={60}
          style={{ height: "100%", breath: "100%" }}
          ref={container}
        >

        </MDBox>
      </MDBox>

    </BasicLayout>
  );
}
