import * as Yup from "yup";
import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    age,
    username,
    // documentnumber,
    password,
    pan,
    adhar,
    // documenttype,
    repeatPassword,
    bankname,
    ifsccode,
    accountnumber,
    accountholdername ,
    mobile, email, referalcode
   
    
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [username.name]: Yup.string().required(username.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
     [pan.name]: Yup.string().required(pan.errorMsg),
     [adhar.name]: Yup.string()
     .required(adhar.errorMsg)
     .min(12, adhar.invalidMsg)
     .max(12, adhar.invalidMsg),
    
     [accountholdername.name]: Yup.string().required(accountholdername.errorMsg),
     [accountnumber.name]: Yup.string().required(accountnumber.errorMsg),
     [ifsccode.name]: Yup.string().required(ifsccode.errorMsg),
     [bankname.name]: Yup.string().required(bankname.errorMsg),
     [referalcode.name]: Yup.string().required(referalcode.errorMsg),
     
     [mobile.name]: Yup.string()
      .required(mobile.errorMsg)
      .min(10, mobile.invalidMsg)
      .max(10, mobile.invalidMsg),
     [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),

    //[file.name]: Yup.string().required(file.errorMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  
  
];

export default validations;