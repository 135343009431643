import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import swal from 'sweetalert';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

function InvestmentApproval() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };


  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function update(item) {
    console.log("90", item)
    
    
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    console.log("97", item)
    await axios
      .put(
        // "https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/update",
        "https://apithf.softwaremathematics.com/investmentitem-service/api/investmentitem/v1/update",
        // "http://localhost:6012/api/investmentprofile/v1/update",
        item
      )
      .then((response) => {
        swal("Investment Approved!", "succesfully update!");


        getData();
      })
      .catch((error) => {
        swal("Withdrawal Approved!", "oops something went wrong!");

      });
  }

  async function updateData(item) {
    item.recstatus = "APPROVED";
    update(item)
  }

  async function rejectData(item) {
    item.recstatus = "REJECT";
    update(item)
  }
  


  function getData() {
    var referalcode = JSON.parse(localStorage.getItem("investment-profile")).referalcode;
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "investmentprofile.name", },
        { Header: "age", accessor: "investmentprofile.age", },

        { Header: "referalcode", accessor: "investmentprofile.referalcode" },


        { Header: "commision type", accessor: "investmentitem.commissiontype" },
        { Header: "commission", accessor: "investmentitem.commission" },
        { Header: "opening balance", accessor: "investmentitem.amount" },
        { Header: "investment term (MM)", accessor: "investmentitem.investmentterm" },
        { Header: "start date", accessor: "investmentitem.fstartdate" },
        { Header: "end date", accessor: "investmentitem.fenddate" },
        { Header: "action", accessor: "action", },
      ],
      rows: [],
    };
    axios
      .get(
        // `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/get?recstatus=WITHDRAWL_REQUESTED`
        // `http://localhost:6012/api/investmentprofile/v1/getAggregationByProfileV2?referalcode=ADA1384&recstatus=OPEN`
        `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/getAggregationByProfileV2?referalcode=${referalcode}&recstatus=OPEN`
      )
      .then((response) => {
        response.data.data.investmentProfileAggregationV2.map((item) => {
          item.action = (
            <span>
              <span>
                <Link to={{}}>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => { updateData(item.investmentitem) }}
                  >
                    Approved
                  </MDButton>

                </Link>


              </span>
              <span>
                <Link to={{}}>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => { rejectData(item.investmentitem) }}
                  >
                    Reject
                  </MDButton>

                </Link>


              </span>
            </span>
          );
          item.investmentitem.fstartdate = new Date(item.investmentitem.startdate).toDateString();
          item.investmentitem.fenddate = new Date(item.investmentitem.enddate).toDateString();

          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Investment Approval
                  </MDTypography>
                </span>

                {/* <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/profile/create-role"}
                >
                  <MdOutlineLibraryAdd />
                </Link> */}
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Type List</DialogTitle>
        <DialogContent style={{ width: 550, height: 150 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Role Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Role Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}


export default InvestmentApproval;