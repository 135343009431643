/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Link, useNavigate } from "react-router-dom";
import BookingCard from "examples/Cards/BookingCard";
import SyncIcon from '@mui/icons-material/Sync';

//axios
import axios from "axios";
// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import MDButton from "components/MDButton";
import { fontSize } from "@mui/system";

function Analytics() {
  const { sales, tasks } = reportsLineChartData;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [investmentprofile, setInnestmentprofile] = useState(JSON.parse(localStorage.getItem("investment-profile")))
  const [totalClosingAmount, setTotalClosingAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [toatlProfiles, setToatlProfiles] = useState(0);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography
          variant="body1"
          color="info"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );
  function navigationpage(){
    alert('thankyou')
  }
  function roundDown(value, digits=2){
    value = (value*(Math.pow(10,digits)));
    value = Math.floor(value);
    value = value/(Math.pow(10,digits));
    return (value);
}
  async function getData() {
    var referalcode = JSON.parse(localStorage.getItem("investment-profile")).referalcode;
    // setLoading(true);

    await axios
      .get(
        `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}`
        // `http://185.207.250.107:9157/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}`
        //  `http://localhost:6011/api/investmentprofile/v1/getAggregationByProfile?referalcode=${referalcode}`
      )
      .then((response) => {
        console.log("63", response)
        setToatlProfiles(response.data.data.investmentProfileAggregation.length);
        var closing = 0;
        var opening = 0;
        for (let i = 0; i < response.data.data.investmentProfileAggregation.length; i++) {
          console.log(response.data.data.investmentProfileAggregation[i]);
          var data = response.data.data.investmentProfileAggregation[i];
          console.log("clos", data.investmentprofile?.closingbalance);
          closing = closing + roundDown(data.investmentprofile?.closingbalance);
          opening = opening + roundDown(data.investmentprofile?.openingbalance);
          // setTotalClosingAmount(closing + data.investmentprofile?.closingbalance);
          // setTotalOpeningAmount(totalOpeningAmount + data.investmentprofile?.openingbalance);
          // }
          // response.data.data.dtoList.map((item) => {
        }
        setTotalClosingAmount(roundDown(closing));
        setTotalOpeningAmount(roundDown(opening));
        setTotalCommission(roundDown(closing - opening));
        // setResponse(response.data.data.investmentProfileAggregation);
        // console.log("====================================");
        // console.log(getResponse);
        console.log(response.data.data.investmentProfileAggregation);
        console.log("====================================");

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        // setLoading(false);
      });
  }
  
  useEffect(() => {
   
    
      getData();
    
   

  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1.5}>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6} >
      <MDButton
      
         color="info"   
      onClick={() => {
        getData();
      }}
    >
    refresh
    </MDButton>
         
           </Grid>
           </Grid>
        <Grid container spacing={3} mt={2}>
       
          <Grid item xs={12} md={6} lg={3}>
          
            <MDBox mb={1.5}>
            <Link to={"/pages/users/investment-list"}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Profiles"
                count={toatlProfiles}
              
              />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
            <Link to={"/pages/users/investment-list"}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Opening"
                count={totalOpeningAmount}
              
              />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
            <Link to={"/pages/users/investment-list"}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Closing"
                count={totalClosingAmount}
             
              />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
         
            <MDBox mb={1.5}>
            <Link to={"/pages/users/investment-list"}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Commission"
                count={totalCommission}
                
               
              />
              </Link>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
