/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "Firstname is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Lastname is required.",
    },
    username: {
      name: "username",
      label: "User Name",  
      type: "text",
      errorMsg: "Username is required.",
    },
    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age is required.",
    },
    pan: {
      name: "pan",
      label: "PAN Number",
      type: "text",
      errorMsg: "PAN is required.",
    },
    adhar: {
      name: "adhar",
      label: "Adhar Number",  
      type: "text",
      errorMsg: "Adhar is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    mobile: {
      name: "mobile",
      label: "Mobile Number",  
      type: "number",
      errorMsg: "Mobile Number is required.",
    },
    // documentnumber: {
    //   name: "documentnumber",
    //   label: "Document Number",
    //   type: "text",
    //   errorMsg: "documentnumber is required.",
    // },
    // documenttype: {
    //   name: "documenttype",
    //   label: "Document Type",  
    //   type: "text",
    //   errorMsg: "documenttype is required.",
    // },
    referalcode: {
      name: "referalcode",
      label: "Referal Code",  
      type: "text",
      errorMsg: "Referal Code is required.",
    },
    accountholdername: {
      name: "accountholdername",
      label: "Account Holdername",  
      type: "text",
      errorMsg: "Account Holdername is required.",
    },
    accountnumber: {
      name: "accountnumber",
      label: "Account Number",  
      type: "number",
      errorMsg: "Account number is required.",
    },
    ifsccode: {
      name: "ifsccode",
      label: "IFSC Code",  
      type: "text",
      errorMsg: "IFSC code is required.",
    },
    bankname: {
      name: "bankname",
      label: "Bank Name",  
      type: "text",
      errorMsg: "Bankname is required.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    twitter: {
      name: "twitter",
      label: "Twitter",
      type: "password",
      errorMsg: "twitter is required.",
      
    },
    
    
   
   
   
  },
};

export default form;