/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import { BarLoader } from "react-spinners";
import DataTable from "examples/Tables/DataTable";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";


// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import { useState,useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import { validationdata } from "./schemas";
import axios from "axios";
import ProfilesList from "examples/Lists/ProfilesList";

const initialValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",

}



function Overview() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("mmu-auth")));
  const [response, setResponse] = useState([]);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({

    initialValues: initialValues,
    validationSchema: validationdata,
    onSubmit: (values, action) => {

      const getData = {
        username: JSON.parse(localStorage.getItem("mmu-auth")).username,
        password: values.new_password.trim(),
        resourcecode: "INVESTMENT"

      };


      axios
        .post(
          // "http://localhost:6013/api/ProfileEntity/v2/setPassword",
          "https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/setPassword",
          getData
        )

        .then((response) => {
          console.log("gfgfgf", response);
          swal("Alert!", "...your password has been sucessfully update!", "success")
            .then((value) => {
              // window.location.reload(true)
              action.resetForm();

            });



        })
        .catch((error) => {
          console.log(error);


        });


    }

  })
  const handleClose = () => {
    setOpen(false);
  };
  function bankDetail(profileid) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: " bank name", accessor: "bankname" },
        { Header: "ifsc code", accessor: "ifsc" },
        { Header: "Acc holder name", accessor: "holdername" },
        { Header: "Account number", accessor: "accountno" },
        { Header: "STATUS", accessor: "recstatus" },
       ,
      ],
      rows: [],
    };
    axios
      .get(
        `https://apithf.softwaremathematics.com/bankaccounts1-service/api/Bankaccounts1/v1/get?profileid=${profileid}`
        // `http://localhost:6001/api/Bankaccounts1/v1/get?profileid=${profileid}`,
      )
      .then((response) => {
        setResponse(response.data.data.dtoList);
        response.data.data.dtoList.map((item) => {
         
        
  
          
          // item.createddate = new Date(item.createddate).toDateString();
  
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    bankDetail(profile?.profileid);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"

                info={{
                  fullName: localStorage.getItem("mmu-auth")
                    ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
                    " " +
                    JSON.parse(localStorage.getItem("mmu-auth")).lastname
                    : "",
                    age: localStorage.getItem("investment-profile") ? JSON.parse(localStorage.getItem("investment-profile")).age : "",
                  referalcode: localStorage.getItem("investment-profile") ? JSON.parse(localStorage.getItem("investment-profile")).referalcode : "",
                  // Hierarchy: localStorage.getItem("investment-profile")?JSON.parse(localStorage.getItem("investment-profile")).hierarchicalcode:"",
                  location: "INDIA",
                  
                  aadharNumber: localStorage.getItem("investment-profile").bankdetail ? JSON.parse(localStorage.getItem("investment-profile")).bankdetail.adhar : "",
                  panNumber: localStorage.getItem("investment-profile").bankdetail ? JSON.parse(localStorage.getItem("investment-profile")).bankdetail.pan : "",
                  mobileNumber: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")).phoneno : "",
                  emailId: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")).emailid : ""

                }}
                social={[

                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />

              <Divider orientation="vertical" sx={{ mx: 0 }} />

            </Grid>
            <Grid item xs={12} sm={8} >
              <MDButton

                // disabled={isSubmitting}
                type="submit"
                variant="gradient"
                color="dark"
                onClick={() => {
                  console.log("submit");
                  setOpen(true)
                }}
              >
                Change Password
              </MDButton>
             
            {!loading && <DataTable table={tableDatas} entriesPerPage={false} showTotalEntries={false}/>}
            </Grid>

            {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
              </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>

      <Dialog open={open} onClose={handleClose}>

        <DialogTitle style={{ textAlign: "center" }}>Update Password</DialogTitle>
        <DialogContent style={{ width: 450, height: 270 }}>
          <form onSubmit={handleSubmit}>
            <MDBox pb={3} px={3}>
              <Grid container spacing={3} mt={1}>

                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="new_password"
                    id="new_password"
                    type="password"
                    label="New Password"
                    autoComplete="off"
                    value={values.new_password}
                    onBlur={handleBlur}
                    onChange={handleChange}

                  />
                  {errors.new_password && touched.new_password ?
                    (<p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.new_password}</p>) : null}
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="confirm_password"
                    id="confirm_password"
                    type="password"
                    autoComplete="off"
                    label="Confirm New Password"
                    value={values.confirm_password}
                    onBlur={handleBlur}
                    onChange={handleChange}

                  />

                  {errors.confirm_password && touched.confirm_password ?
                    (<p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.confirm_password}</p>) : null}
                </Grid>
                <Grid item xs={12}>

                  <MDBox mt={-2} ml={27}  >
                    <MDButton variant="gradient" color="dark" size="small" type="submit"
                    // onClick={updatePassword}
                    >
                      update password
                    </MDButton>
                  </MDBox>


                </Grid>
              </Grid>


            </MDBox>
          </form>
        </DialogContent>


      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
