// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "layouts/pages/InvestmentItem/new-InvestmentItem/componets/FormField";

//date picker
import MDDatePicker from "components/MDDatePicker";
//dropdown
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";


import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function InvestmentItemInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [comisssionValue, setComisssionValue] = useState(null);
  const [comisssionlist, setComisssionlist] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const [closingDate, setClosingDate] = useState(new Date());
  const [color] = useState("#344767");



  const { formField, values, errors, touched } = formData;

  const { comisssiontype, comisssion, investmentterm, amount,transationNo} = formField;
  var {

    comisssion: comisssionV,
    investmentterm: investmenttermV,
    amount: amountV,
    transationNo:transationNoV
    


  } = values;
  values.startdate = startDate;
  values.enddate = endDate;
  // Dropdown default props
  const defaultPropscommissiontype = {
    options: !comisssionlist
      ? [
        { name: "Percentage", code: "X" },
        { name: "Flat", code: "y" },
      ]
      : comisssionlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };


  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add Investment</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropscommissiontype}
                value={comisssionValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setComisssionValue(newValue);

                  values.comisssiontype = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.comisssiontype && touched.comisssiontype}
                    // success={comisssiontype.length > 0 && !errors.comisssiontype}
                    variant="standard"
                    label={comisssiontype.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={comisssion.type}
                label={comisssion.label}
                name={comisssion.name}
                value={comisssionV}
                placeholder={comisssion.placeholder}
                error={errors.comisssion && touched.comisssion}
                success={comisssion.length > 0 && !errors.comisssion}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
              <FormField
                type={investmentterm.type}
                label={investmentterm.label}
                name={investmentterm.name}
                value={investmenttermV}
                placeholder={investmentterm.placeholder}
                error={errors.investmentterm && touched.investmentterm}
                success={investmenttermV.length > 0 && !errors.investmentterm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={amount.type}
                label={amount.label}
                name={amount.name}
                value={amountV}
                placeholder={amount.placeholder}
                error={errors.amount && touched.amount}
                success={amountV.length > 0 && !errors.amount}
              />
            </Grid>


          </Grid>
          <Grid container spacing={3}>
        
          <Grid item xs={12} sm={6}>
              <FormField
                type={transationNo.type}
                label={transationNo.label}
                name={transationNo.name}
                value={transationNoV}
                placeholder={transationNo.placeholder}
                error={errors.transationNo && touched.transationNo}
                success={transationNoV.length > 0 && !errors.transationNo}
              />
            </Grid>
            <Grid item xs={12} sm={3}>


              <MDDatePicker
                input={{ placeholder: "Select Start Date" }}
                selected={startDate}
                // onChange={(date) => {
                //   setStartDate(date)
                //   values.startdate = date;
                //   console.log("129", date)
                // }
                // }
                onChange={setStartDate}

              />
            </Grid>
            <Grid item xs={12} sm={3}>

              <MDDatePicker
                input={{ placeholder: "Select End Date" }}
                selected={endDate}
                // onChange={(date) => {
                //   setEndDate(date)
                //   values.enddate = date;
                //   console.log("145", date)
                // }
               // }
               onChange={setEndDate}

              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>

              <MDDatePicker
                input={{ placeholder: "Select Closing Date" }}
                selected={closingDate}
                onChange={(date) => {
                  setClosingDate(date);
                  values.closingdate = date;
                  console.log("156", date)
                }
                }

              />
            </Grid> */}


          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for InvestmentItemInfo
InvestmentItemInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default InvestmentItemInfo;




