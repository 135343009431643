
import Analytics from "layouts/dashboards/analytics";

import ProfileOverview from "layouts/pages/profile/profile-overview";



import SignInBasic from "layouts/authentication/sign-in/basic";


// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////



// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import Investment from "components/MMU/components/Investment/Investment";
import InvestmentList from "components/MMU/components/Investment/InvestmentList";
import InvestmentRedeem from "components/MMU/components/Investment/InvestmentRedeem";
import WithDrawalapproval from "components/MMU/components/Investment/WithDrawalapproval";
import InvestmentApproval from "components/MMU/components/Investment/InvestmentApproval";
import Familytree from "layouts/dashboards/familytree";
import ProfileList from "components/MMU/components/Investment/ProfileList";





const routesAfterlogin = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      // {
      //   name: "Family Tree",
      //   key: "family-tree",
      //   route: "/dashboards/family-tree",
      //   component: <Familytree />,
      // },
      // {
      //   name: "Profile Tree",
      //   key: "profile-tree",
      //   route: "/dashboards/profile-tree",
      //   component: <Familytree />,
      // },
     
    ],
  },
 

  { type: "title", title: "Masters", key: "title-masters" },

  // {
  //   type: "collapse",
  //   name: "User Master",
  //   key: "mausermaster",
  //   icon: <Icon fontSize="medium">person</Icon>,
  //   collapse: [
      
  //     {
  //       name: "Users List",
  //       key: "users-list",
  //       route: "/pages/users/users-list",
  //       component: <UsersListTable />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Investment Menu",
    key: "investmentmaster",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      
      {
        name: "Add Investment",
        key: "add-investment",
        route: "/pages/users/add-investment",
        component: <Investment/>,
      },
      {
        name: "Investment List",
        key: "investmentlist",
        route: "/pages/users/investment-list",
        component: <InvestmentList/>,
      },
      {
        name: "Investment Withdrawal",
        key: "investmentredeem",
        route: "/pages/users/investment-redeem",
        component: <InvestmentRedeem/>,
      },
      {
        name: "Withdrawal Approval",
        key: "withdrawalapproval",
        route: "/pages/users/withdrawal-approval",
        component: <WithDrawalapproval/>,
      },{
        name: "Investment Approval",
        key: "InvestmentApproval",
        route: "/pages/users/investment-approval",
        component: <InvestmentApproval/>,
      },
      {
        name: "Profile List",
        key: "profile-list",
        route: "/pages/users/profile-list",
        component: <Familytree/>,
      },
    ],
  },
 

 
];
export default routesAfterlogin