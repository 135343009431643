/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



import checkout from "layouts/pages/InvestmentItem/new-InvestmentItem/schemas/form";
const {
  formField:{comisssion,investmentterm,amount,transationNo},
} = checkout;

const initialValues = {
  
  [amount.name]: "",
  [comisssion.name]: "",
  [investmentterm.name]: "",
  [transationNo.name]: "",
  
 
 
};

export default initialValues;
