import React, { useEffect, useState } from "react";
import "./styles.css";
import Chart from "./Chart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import axios from "axios";
import MDAvatar from "components/MDAvatar";
import image from "assets/images/team-4.jpg"
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
export default function Familytree() {
  const [iProfile, setIprofile] = useState(JSON.parse(localStorage.getItem("investment-profile")));
  const [data, setData] = useState({
    id: 'root',
    name: 'loading...',
    dto: { name: 'loading....' }
  });

  function getData(referalCode) {
    // setLoading(true);
    axios
      .get(
        `https://apithf.softwaremathematics.com/investmentprofile-service/api/investmentprofile/v1/getTree/${referalCode}`
      )
      .then((response) => {
        response.data.data.tree.id = "root"
        setData(response.data.data.tree);
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }






  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.dto.name}>
      <MDBox p={1}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox key={nodes.id} component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
              <MDAvatar src={image} alt="something here" shadow="md" />
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <MDTypography variant="button" fontWeight="medium">
                {nodes.dto.name}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                Referal: {nodes.dto.referalcode}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                Age: {nodes.dto.age}
              </MDTypography>
            </MDBox>

          </MDBox>

        </MDBox>
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </MDBox>
    </TreeItem>

  );

  useEffect(() => {
    getData(iProfile?.referalcode);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card >
        <MDBox m={2}>
          <TreeView

            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: "100%", flexGrow: 1, maxWidth: "100%", overflowY: 'auto' }}

          >
            {renderTree(data)}
          </TreeView>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}


