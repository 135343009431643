/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function BankDetailInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { 
    bankname, ifsccode, accountnumber, accountholdername} = formField;
  const { 
   
    accountholdername: accountholdernameV,
    accountnumber: accountnumberV,
    ifsccode: ifsccodeV,
    bankname: banknameV,
    
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Bank Detail
      </MDTypography>
      <MDBox mt={1.625}>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={accountholdername.type}
                label={accountholdername.label}
                name={accountholdername.name}
                value={accountholdernameV}
                placeholder={accountholdername.placeholder}
                error={errors.accountholdername && touched.accountholdername}
                success={accountholdernameV.length > 0 && !errors.accountholdername}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormField
                type={accountnumber.type}
                label={accountnumber.label}
                name={accountnumber.name}
                value={accountnumberV}
                placeholder={accountnumber.placeholder}
                error={errors.accountnumber && touched.accountnumber}
                success={accountnumberV.length > 0 && !errors.accountnumber}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={ifsccode.type}
                label={ifsccode.label}
                name={ifsccode.name}
                value={ifsccodeV}
                placeholder={ifsccode.placeholder}
                error={errors.ifsccode && touched.ifsccode}
                success={ifsccodeV.length > 0 && !errors.ifsccode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormField
                type={bankname.type}
                label={bankname.label}
                name={bankname.name}
                value={banknameV}
                placeholder={bankname.placeholder}
                error={errors.bankname && touched.bankname}
                success={banknameV.length > 0 && !errors.bankname}
              />
            </Grid>
          </Grid>
       
      </MDBox>
    </MDBox>
  );
}

// typechecking props for BankDetailInfo
BankDetailInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BankDetailInfo;
