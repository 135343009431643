/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-role-form",
  formField: {
    
    comisssiontype: {
      name: "commissiontype",
      label: "Commission Type",
      type: "text",
      errorMsg: "commissiontype is required.",
    },
    comisssion: {
      name: "commission",
      label: "Commission",   
      type: "number",
      errorMsg: "commission is required.",
    },
    investmentterm: {
      name: "investmentterm",
      label: "Investment Term(Months)",   
      type: "number",
      errorMsg: "investment term is required.",
    },
    amount: {
      name: "amount",
      label: "Amount",   
      type: "number",
      errorMsg: "amount is required.",
    },
    transationNo: {
      name: "transationNo",
      label: "Transation Number",   
      type: "text",
      errorMsg: "Transation Number is required.",
    },
   
  
  },
};

export default form;
